import React from "react";
import Bookings from "./Bookings";
import { useBkg } from "./contexts/BadgeContext";
import { useAcct } from "./contexts/BadgeContext";

const BookingInfo = ({ getPersons }) => {
  const bkgInfo = useBkg();
  const acctInfo = useAcct();

  return (
    <>
      {bkgInfo &&
        bkgInfo.map((bkg) => (
          <Bookings
            key={bkg.BookingGUID}
            BookingID={bkg.BookingID}
            BookingGUID={bkg.BookingGUID}
            AccountID={acctInfo[0].AccountID}
            FreeCards={bkg.FreeCards}
            TemplateId={bkg.TemplateId}
            ImageLocations={bkg.ImageLocations}
            BadgeStatusID={bkg.BadgeStatusID}
            date={bkg.PhotoDate}
            status={bkg.BadgeStatus}
            SchYr={bkg.SchoolYear}
            getPersons={getPersons}
          />
        ))}
    </>
  );
};

export default BookingInfo;
