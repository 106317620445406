import axios from "../utils/axios";
import React, { useState, useEffect, useMemo } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";

function EditBadge({ badge, setBadge, inCompletePersons, setIncompletePersons, completePersons, setCompletedPersons }) {
  
  const [show, setShow] = useState(false);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { register, handleSubmit, reset, getValues, formState: { isDirty } } = useForm({
    defaultValues: useMemo(() => {
      return badge
    }, [badge])
  });

  useEffect(() => {
    
    reset(badge);

  }, [badge]);

  const onSubmit = async() => {

    const ctyStZp = getValues("cityStateZip")

    const regex = /^(.*?),\s*([^,]+)\s+(\d+)$/;
    const matches = ctyStZp.match(regex);

    const city = matches[1].trim();
    const state = matches[2].trim();
    const zip = matches[3].trim();
    
    try {


    const res = await axios
      .put(`/editAcct`, null, { 
          params: {
            AccountID: badge.AccountID,
            BookingGUID: badge.BookingGUID,
            PersonID: badge.PsnID,
            FirstName: getValues("firstName"),
            LastName: getValues("lastName"),
            AccountNameLine1: getValues("accountName1"),
            AccountNameLine2: getValues("accountName2"),
            Street: getValues("street"),
            City: city,
            State: state,
            Zip: zip,
            Phone: getValues("phone"),
            SchoolYear:  getValues("schoolYear"),
            Title: getValues("title"),
          }
        }
      )
    
    await setBadge(prevData => ({...prevData,
      firstName: res.data[0].FirstName,
      lastName: res.data[0].LastName,
      accountNameLine1: res.data[0].AccountNameLine1,
      accountNameLine2: res.data[0].AccountNameLine2,
      street: res.data[0].Street,   
      phone: res.data[0].Phone, 
      cityStateZip: ctyStZp,
      schoolYear:  res.data[0].SchoolYear,
      title: res.data[0].Title
    }))
    //if firstName or lastName0 Changes update list
    if(isDirty) {
    setIncompletePersons(
      inCompletePersons.map(p => p.PersonId === badge.PsnID
        ? {...p, FirstName: res.data[0].FirstName, LastName: res.data[0].LastName}
        : p 
      )
    )
    setCompletedPersons(
      completePersons.map(p => p.PersonId === badge.PsnID
        ? {...p, FirstName: res.data[0].FirstName, LastName: res.data[0].LastName}
        : p 
      )
    )
    }
    if(res.status === 200) {
      setIsSubmitSuccessful(true)
      
    } else "failed to update record"

    }
    catch (err) {
      console.error(err.message)
    }
    finally {
      handleClose()
    }
  }
  

  useEffect(() => {

    if(isSubmitSuccessful) {

      setIsSubmitSuccessful(false)
      reset(badge)
      
    }
  }, [isSubmitSuccessful, badge, setBadge]);

  return (
    <>
      <div className="d-flex justify-content-center mt-5">
        <Button id="badBtn" variant="outline-info" onClick={handleShow}>
          Edit {badge.firstName}'s Info
        </Button>
      </div>
      <Modal show={show} onHide={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)} >
        <Modal.Header closeButton>
          <Modal.Title className="text-info"  >{badge.firstName}'s Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group className="mb-3" >
              <Form.Label className="text-info" >Account Name 1</Form.Label>
              <Form.Control
                {...register("accountName1")}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label className="text-info"  >Account Name 2</Form.Label>
              <Form.Control
                {...register("accountName2")}
              />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label className="text-info"  >First Name</Form.Label>
              <Form.Control
                {...register("firstName")}
              />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label className="text-info"  >Last Name</Form.Label>
              <Form.Control
                {...register("lastName")}
              />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label className="text-info"  >Street</Form.Label>
              <Form.Control
                {...register("street")}
              />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label className="text-info" >City,State,Zip</Form.Label>
              <Form.Control
                {...register("cityStateZip")}
              />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label className="text-info"  >Phone--format however you like or remove it</Form.Label>
              <Form.Control
                {...register("phone")}
              />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label className="text-info"  >School Year</Form.Label>
              <Form.Control
                {...register("schoolYear")}
              />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Label className="text-info"  >Title</Form.Label>
              <Form.Control
                {...register("title")}
              />
            </Form.Group>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" onClick={onSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
export default EditBadge;
