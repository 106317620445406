import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import format from 'date-fns/format'
import Container from "react-bootstrap/Container"
import { useNavigate } from "react-router-dom"
import axios from '../utils/axios'


const Bookings = ({ date, status, BadgeStatusID, BookingGUID, BookingID,  getPersons }) => {

	const formatDate = format(new Date(date), 'MM/dd/yy')

	const navigate = useNavigate()
	
	const handlePerson = async(e) => {
		e.preventDefault()
		try {
			const res = await axios.get(`/bookingInfo/?BookingGUID=${BookingGUID}`)
			getPersons(res.data[0].BookingID)
			navigate('/badges')
		}
		catch(err) 
		{console.error(err) }
	}

	return (    
			<Container className="w-100 h-50" >
				<div className="d-flex flex-column justify-content-center" >
					<Row>
							<Col className="col-3" >
							<h5 className='colName' >Photo Day</h5>
							</Col>
							<Col className="col-3" >
							<h5 className="colName" >Booking #</h5>
							</Col>
							<Col className="col-3" >
							<h5 className='colName' >Badge Status</h5>
							</Col>
					</Row>
					<Row>
							<Col className="col-3" >
							<span className='rowName' >{formatDate}</span>
							</Col>
							<Col className="col-3" >
							<span className='rowName' >{BookingID}</span>
							</Col>
							<Col className="col-3" >
							<span className="statusName" >{status}</span>
							</Col>
							<Col>
							{BadgeStatusID <= 3 
									? <Button onClick={(e) => handlePerson(e) } style={{textDecoration: "none"}} variant="link" size="sm" block="true" >
											Create Badges
										</Button>
									: <span className="done">Badges Completed</span>
								}
							</Col>
					</Row>
				</div>
			</Container >
    )
}
export default Bookings