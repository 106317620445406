import axios from "axios"

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        "Access-Control-Allow-Private-Network": "true",
    },

})

export default instance