import React from "react";

function Header() {
  return (
    <div className="w-100 p-2 mb-2 text-white text-center banner">
      Complete the badges by: editing them if needed, then approving it and
      finally finalizing them.
    </div>
  );
}

export default Header;
