import React from "react"
import Image from "react-bootstrap/Image"
import Stack from "react-bootstrap/Stack"
import Logo from "../assets/Logo.png"
import Container from "react-bootstrap/Container"
import BookingInfo from "./BookingInfo"
import AccountInfo from "./AccountInfo"

const Dashboard = ({ getPersons }) => {

  return (
    <Container className="d-flex align-items-center " data-testid="account-info" >
      <Stack gap={3} className=" align-items-center flex-nowrap" >
      <Image data-testid="wyp-picture" className="w-25 object-fit-contain" src={Logo} alt="picture of logo" />
        <h1 data-testid="id-badges" className='title'>Id Badges</h1>
        <AccountInfo data-testid="account-info" /> 
        <Container fluid='md' data-testid="booking-info">      
        <BookingInfo getPersons={getPersons} />
        </Container>
      </Stack>      
    </Container>
  )
}
export default Dashboard