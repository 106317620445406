import React from "react"
import { useAcct } from './contexts/BadgeContext'


const AccountInfo = () => {

  const acctInfo = useAcct()

	const act = acctInfo ? acctInfo[0].AccountName : null

	return (
			<>

				<h4 className="school" >{act}</h4>

			</>
	)
}

export default AccountInfo