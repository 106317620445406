import React from "react";
import { useForm } from "react-hook-form";
import TmpNoLogo from "../assets/TmpNoLogo.jpg";
import Form from "react-bootstrap/esm/Form";
import Row from "react-bootstrap/esm/Row";
import Image from "react-bootstrap/esm/Image";
import EditBadge from "./EditBadge";
import Resizer from "react-image-file-resizer";

const BadgeForm = ({
  badge,
  setBadge,
  setBadgeNodes,
  inCompletePersons,
  setCompletedPersons,
  setIncompletePersons,
}) => {
  const { register } = useForm();

  const template10 = {
    backgroundImage: `url(${TmpNoLogo})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "30%",
    maxWidth: "480px",
    maxHeight: "342px",
    boxShadow: "4px 4px 5px #888888",
    padding: "0",
    paddingTop: "2%",
    display: "flex",
    justifyContent: "space-between",
  };

  return (
    <>
      <div className="container" style={template10}>
        <form>
          <Form.Group className="formGrp" controlId="formGroupBadge">
            <Form.Control
              type="hidden"
              {...register("AccountID")}
              value={badge.AccountID}
            />
            <Form.Control
              type="hidden"
              {...register("BookingID")}
              value={badge.BookingGUID}
            />
            <Form.Control
              type="hidden"
              {...register("PsnID")}
              value={badge.psnID}
            />

            <Form.Control
              className="information"
              size="sm"
              {...register("accountName1")}
              value={badge.accountName1}
            />
            <Form.Control
              className="information"
              size="sm"
              {...register("accountName2")}
              value={badge.accountName2}
            />
            <div className="image-wrap">
              <Image className="img-fluid w-100" src={badge.imageURL} />
            </div>
            <Form.Control
              {...register("firstName")}
              className="mt-2 ms-4"
              size="sm"
              value={badge.firstName}
            />

            <Form.Control
              {...register("lastName")}
              className="ms-4"
              size="sm"
              value={badge.lastName}
            />

            <Form.Control
              {...register("street")}
              className="ms-4"
              size="sm"
              value={badge.street}
            />
            <Form.Control
              {...register("cityStateZip")}
              className="ms-4"
              size="sm"
              value={badge.cityStateZip}
            />
            <Form.Control
              {...register("phone")}
              className="ms-4"
              size="sm"
              value={badge.phone}
            />

            <Row className="ms-1 w-100 flex-nowrap">
              <Form.Control
                {...register("schoolYear")}
                className="ms-4"
                size="sm"
                style={{ color: "white" }}
                value={badge.schoolYear}
              />

              <Form.Control
                {...register("title")}
                className="mt-1 ms-5"
                style={{
                  fontSize: "13px",
                  height: "15px",
                  borderRadius: "3px",
                  width: "165px",
                }}
                value={badge.title}
              />
            </Row>
          </Form.Group>
        </form>
      </div>
      <EditBadge
        badge={badge}
        setBadge={setBadge}
        setBadgeNodes={setBadgeNodes}
        inCompletePersons={inCompletePersons}
        setIncompletePersons={setIncompletePersons}
        setCompletedPersons={setCompletedPersons}
      />
    </>
  );
};

export default BadgeForm;
