import { useState, useContext, createContext } from "react";
import { Routes, Route } from "react-router-dom";
import Badges from "./Badges";
import Dashboard from "./Dashboard";
import BadgeForm from "./BadgeForm";
import EditBadge from "./EditBadge";
import Completed from "./Completed";
import { AcctProvider } from "./contexts/BadgeContext";
import axios from '../utils/axios'
//
const PersonsContext = createContext();

const params = new URLSearchParams(location.search);

const GUID = params.get('GUID');
const BkgID = params.get('BookingID');

export const usePersons = () => {
  return useContext(PersonsContext);
};

const App = () => {

  const [persons, setPersons] = useState([{}]);

  const getPersons = async (id) => {

    const res = await axios.get(`/getPersonsByBookingID?BookingID=${id}`);
    setPersons(res.data);

  };

  return (
    <AcctProvider GUID={GUID} BkgID={BkgID}>
      <PersonsContext.Provider value={persons}>
        <Routes>
          <Route path="/" element={<Dashboard getPersons={getPersons} />} />
          <Route path="/badges" element={<Badges />} />
          <Route path="/badgeForm" element={<BadgeForm />} />
          <Route path="/editBadge" element={<EditBadge />} />
          <Route path="/completed" element={<Completed />} />
          <Route path="*" element={<h1>404 Not Found</h1>} />
        </Routes>
      </PersonsContext.Provider>
    </AcctProvider>
  );
};

export default App;
