import { useState, useEffect } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/esm/Button";
import BadgeForm from "./BadgeForm";
import InCompletePersonList from "./InCompletePersonList";
import CompletedPersonList from "./CompletedPersonList";
import axios from "../utils/axios";
import { usePersons } from "./App";
import { useBkg } from "./contexts/BadgeContext";
import { useAcct } from "./contexts/BadgeContext";
import { useNavigate } from "react-router-dom";
import Copyright from "./Copyright";
import Header from "./Header";

const Badges = () => {
  const [reload, setReload] = useState(false);
  const [badgeNodes, setBadgeNodes] = useState([]);
  const [currentPsnID, setCurrentPsnID] = useState();
  const [inCompletePersons, setIncompletePersons] = useState([]);
  const [completedPersons, setCompletedPersons] = useState([]);
  const [isApproved, setIsApproved] = useState(false);

  const navigate = useNavigate();

  const persons = usePersons();
  const bkgInfo = useBkg();
  const acctInfo = useAcct();

  const [ID, setID] = useState({
    BadgeID: "",
    BkID: bkgInfo ? bkgInfo[0].BookingID : null,
    AtID: acctInfo ? acctInfo[0].AccountID : null,
    AtGUID: acctInfo ? acctInfo[0].AccountGUID : null,
    GUID: bkgInfo ? bkgInfo[0].BookingGUID : null,
    psnID: currentPsnID,
  });

  const [badge, setBadge] = useState({
    AccountID: ID.AtID,
    BookingGUID: ID.GUID,
    PsnID: ID.psnID,
    imageURL: "",
    schoolYear: "",
    firstName: "",
    lastName: "",
    accountName1: "",
    accountName2: "",
    cityStateZip: "",
    phone: "",
    title: "",
  });

  const person = persons.find((psn) => psn.PersonId === currentPsnID);

  useEffect(() => {
    setIncompletePersons(persons.filter((person) => person.BadgeStatusId < 3));
    setCompletedPersons(persons.filter((person) => person.BadgeStatusId === 3));
    setIsApproved(false);
  }, [persons]);

  const handleApprove = (id) => {
    const person = inCompletePersons.find((p) => p.PersonId === id);
    setIncompletePersons((prevData) =>
      prevData.filter((p) => p.PersonId !== id)
    );
    setCompletedPersons((prevData) => [
      ...prevData,
      { ...person, BadgeStatusId: 3 },
    ]);
  };

  const completeBadgeNodes = async (id) => {
    try {
      const res = await axios.post(
        `/insertBadgeNode?BadgeID=${id}`,
        badgeNodes
      );
      if (res.status === 200) {
  
      } else console.log("An Error with Badge Has Occurred");
    } catch (err) {
      console.error(err.message);
    }
  };

  const sendToBuyCutePix = () => {
    const Acct = acctInfo[0];
    const badgeCount = completedPersons.length;
    const bcpURL = `https://buycutepix.com/cart/32644217438305:${badgeCount}?&checkout[email]=${Acct.Email}&checkout[shipping_address][country]=US&checkout[shipping_address][first_name]=${Acct.AccountName}&checkout[shipping_address][address1]=${Acct.Street}&checkout[shipping_address][city]=${Acct.City}&checkout[shipping_address][province]=${Acct.State}&checkout[shipping_address][zip]=${Acct.Zip}&customer_address[customer_address][phone]=${Acct.Phone}&attributes[where-from]=${bkgInfo[0].BookingID}`;
    return (window.location = bcpURL);
  };

  const approveBadge = async () => {
    try {
      const res = await axios.post("insertBadge", null, {
        params: {
          PersonID: badge.PsnID,
          TemplateID: 4,
          BookingID: bkgInfo[0].BookingID,
        },
      });

      handleApprove(currentPsnID);

      setID((prevData) => ({ ...prevData, BadgeID: res.data[0].BadgeID }));

      completeBadgeNodes(res.data[0].BadgeID);
    } catch (err) {
      console.error(err);
    }
  };

  const restartAllBadges = async () => {
    try {
      await axios.delete(`/deleteBadgesForBookingGUID?BookingGUID=${ID.GUID}`);
      setCompletedPersons([]);
      setIncompletePersons(
        persons.filter((person) => person.BadgeStatusId < 3)
      );
      navigate('/')
    } catch (err) {
      console.error(err.message);
    }
  };
  const finishedWithBadges = async () => {
    try {
      await axios.put(
        `/updateStatusToSubmitted?BookingID=${bkgInfo[0].BookingID}`
      );

      if (bkgInfo[0].FreeCards == 1) {
        navigate(
          `/completed`
        );

      } else sendToBuyCutePix();
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <>
    <Header />
    <Container className="d-flex justify-content-center">
      <Row className="mt-5">
        <Col className="nameList">
            <div className="text-start list">Incomplete: </div>
          <p className="ltxt" >Click On Name Below To Select The Badge To Be Approved or
                Edited.</p>
          <InCompletePersonList
            data-testid="incomplete"
            currentPsnID={currentPsnID}
            setCurrentPsnID={setCurrentPsnID}
            setReload={setReload}
            reload={reload}
            AccountID={ID.AtID}
            BookingGUID={ID.GUID}
            badge={badge}
            setBadge={setBadge}
            BookingID={ID.BkID}
            badgeNodes={badgeNodes}
            setBadgeNodes={setBadgeNodes}
            completedPersons={completedPersons}
            inCompletePersons={inCompletePersons}
          />
          <>
              <div className="text-start list">Completed:</div>
            <p className="ltxt" >The List Below Are Badges That Have Been Approved By You.</p>
            <CompletedPersonList
              data-testid="completed"
              setBadge={setBadge}
              badge={badge}
              setBadgeNodes={setBadgeNodes}
              badgeNodes={badgeNodes}
              completedPersons={completedPersons}
              setCurrentPsnID={setCurrentPsnID}
            />
          </>
        </Col>
        <Col className="template">
          <BadgeForm
            data-testid="badge-form"
            AccountID={ID.AtID}
            BookingGUID={ID.GUID}
            BookingID={ID.BkID}
            badge={badge}
            setBadge={setBadge}
            badgeNodes={badgeNodes}
            setBadgeNodes={setBadgeNodes}
            inCompletePersons={inCompletePersons}
            setIncompletePersons={setIncompletePersons}
            setCompletedPersons={setCompletedPersons}
          />
          <Stack gap={3} className="mt-3 mb-5 align-items-center">
              <Button
                data-testid="approve"
                onClick={(e) => approveBadge(e)}
                id="badBtn"
                variant="outline-warning"
                size="lg"
                disabled={inCompletePersons.length === 0}
              >
                Approve Badge
                <p className="btn-text" >
            Make sure all the badge information is correct before clicking here to approve the badge. It can not be Edited after it is approved.
            </p>
              </Button>
              <Button
                onClick={(e) => restartAllBadges(e)}
                id="badBtn"
                variant="outline-danger"
                size="lg"
                data-testid="restart"
              >
                Restart All Badges
                <p className="btn-text" >
                Click Here to go back and edit Account Information. You will
                  LOSE ALL PROGRESS.
            </p>
              </Button>          
              <Button
                onClick={(e) => finishedWithBadges(e)}
                id="badBtn"
                variant="outline-success"
                size="lg"
                data-testid="finished"
              >
                Finished With Badges
                <p className="btn-text" >
            Click Here When You Are Finished With All The Badges.
            </p>
              </Button>
          </Stack>
        </Col>
        <Copyright />
      </Row>
    </Container>
    </>
  );
};

export default Badges;
