import axios from "../utils/axios";
import { useEffect, useCallback, useState } from "react";
import { Link } from "react-router-dom";

const InCompletePersonList = ({
  completedPersons,
  setBadge,
  setCurrentPsnID,
  setBadgeNodes,
  inCompletePersons,
}) => {

  const [idx, setIdx] = useState(0);

  const loadBadge = useCallback(
    async (id, index) => {
      setCurrentPsnID(id);
      setIdx(index);

      try {
        const res = await axios.get(
          `/getPersonById?PersonID=${id}&TemplateID=4`
        );
        await setBadgeNodes(res.data);
        setBadge((prevData) => ({
          ...prevData,
          PsnID: id,
          imageURL: res.data[0].ImageURL,
          schoolYear: res.data[1].DataValue,
          firstName: res.data[2].DataValue,
          lastName: res.data[3].DataValue,
          accountName1: res.data[4].DataValue,
          accountName2: res.data[5].DataValue,
          street: res.data[6].DataValue,
          cityStateZip: res.data[7].DataValue,
          phone: res.data[8].DataValue,
          title: res.data[9].DataValue,
        }));
      } catch (err) {
        console.error(err.message);
      }
    },
    [setBadgeNodes, setBadge, setCurrentPsnID]
  );

  useEffect(() => {

    if (inCompletePersons.length > 0 && inCompletePersons[idx].PersonId) {
      loadBadge(inCompletePersons[idx].PersonId, idx);

    } else if (completedPersons.length > 0 && completedPersons[idx].PersonId) {
      loadBadge(completedPersons[idx].PersonId, idx);
    } 
  }, [inCompletePersons, completedPersons]);

  return (
    <>
      {inCompletePersons ? (
        inCompletePersons.map((prsn, index) => (
          <div className="text-start incomplete" key={prsn.PersonId}>
            <span onClick={() => loadBadge(prsn.PersonId, index)}>
              <Link
                className="inc_person"
                to="/badges"
              >{`${prsn.FirstName} ${prsn.LastName}`}</Link>
            </span>
          </div>
        ))
      ) : (
        <span>None</span>
      )}
    </>
  );
};

export default InCompletePersonList;
