import React from "react"
import Stack from "react-bootstrap/Stack"
import Image from "react-bootstrap/Image"
import Logo from "../assets/Logo.png"
import Container from "react-bootstrap/Container"

function Completed() {

  return (

    <Container className="d-flex align-items-center " data-testid="" >
      <Stack gap={3} className=" align-items-center flex-nowrap" >
      <Image data-testid="wyp-picture" className="mt-5 object-fit-contain" src={Logo} alt="picture of logo" />
      <div className="mt-5 mb-5 " >
        <h2>
          You have completed your Badges.
        </h2>
        <h4>
          You should receive them in 5 - 7 business days.
        </h4>
        <h5 className="mt-5" >
          Thank You, 
        </h5>
        <h5>
        Wonder Years Portraits.
        </h5>
      </div>
      </Stack>
    </Container>

  )
}

export default Completed