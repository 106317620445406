import React, { createContext, useContext, useState, useEffect  } from "react"
import axios from '../../utils/axios'

const AcctContext = createContext()
const BkgContext = createContext()

export const useAcct = () => {
	return useContext(AcctContext)
}

export const useBkg = () => {
	return useContext(BkgContext)
}

export const AcctProvider = ({ children, GUID, BkgID }) => {

	const [acctInfo, setAcctInfo] = useState()
	const [bkgInfo, setBkgInfo] = useState()


	const getAcctInfo = () => {
		let endpoints = [
			`/accountInfo?GUID=${GUID}`,
			`/bookings?BookingID=${BkgID}`,
		]
		Promise.all(endpoints.map(endpoint => axios.get(endpoint)))
			.then(([
				{data: acct},
				{data: bkg}, 
				]) => {
				setAcctInfo(acct)
				setBkgInfo(bkg)
			})
			.catch(err => console.log(err))         
	}

	useEffect(() => {
		getAcctInfo()
	}, [])

	return (
		<AcctContext.Provider value={acctInfo}>
			<BkgContext.Provider value={bkgInfo}>
				{children}
			</BkgContext.Provider>
		</AcctContext.Provider>
		
	)
}

