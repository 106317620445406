import { useState } from "react";
import axios from "../utils/axios";
import { Link } from "react-router-dom";

const CompletedPersonList = ({
  completedPersons,
  setBadgeNodes,
  setBadge,
  setCurrentPsnID,
}) => {

  const [i, setI] = useState(0)

  const loadBadge = async (id, index) => {
    setCurrentPsnID(id);
    setI(index)
    try {
      const res = await axios.get(`/savedNodesByPersonID?PersonID=${id}`);
      await setBadgeNodes(res.data);
      setBadge((prevData) => ({
        ...prevData,
        PsnID: id,
        imageURL: res.data[0].ImageURL,
        schoolYear: res.data[1].TextValue,
        firstName: res.data[2].TextValue,
        lastName: res.data[3].TextValue,
        accountName1: res.data[4].TextValue,
        accountName2: res.data[5].TextValue,
        street: res.data[6].TextValue,
        cityStateZip: res.data[7].TextValue,
        phone: res.data[8].TextValue,
        title: res.data[9].TextValue,
      }));
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <>
      {completedPersons.length > 0 ? (
        completedPersons.map((prsn, index) => (
          <div className="text-start incomplete" key={prsn.PersonId}>
            <span
              onClick={() => {
                loadBadge(prsn.PersonId, index);
              }}
            >
              <Link
                className="inc_person"
                to="/badges"
              >{`${prsn.FirstName} ${prsn.LastName}`}</Link>
            </span>
          </div>
        ))
      ) : (
        <div className="text-start" > None </div>
      )}
    </>
  );
};

export default CompletedPersonList;
